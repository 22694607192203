<template>
  <div>
    <input
      :disabled="readOnly"
      v-model="value"
      type="range"
      :id="setting.name"
      :min="setting.minValue"
      :max="setting.maxValue"
      :step="setting.step"
    />
    <label :for="setting.name">{{ setting.displayName }} {{ value }}</label>
  </div>
</template>

<script>
import { SettingSlider } from "../../code/classes/SettingSlider";

export default {
  props: {
    readOnly: { type: Boolean, default: false },
    setting: { type: SettingSlider },
  },
  computed: {
    value: {
      get() {
        return this.setting.value;
      },
      set(value) {
        this.setting.SetValue(value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
</style>
