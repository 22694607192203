<template>
  <div>
    <button
      v-for="option in setting.options"
      v-bind:key="option"
      :disabled="setting.value === option"
      @click="select(option)"
    >
      {{ option }}
    </button>
    <label>{{ setting.displayName }}</label>
  </div>
</template>

<script>
import { SettingChoice } from "../../code/classes/SettingChoice";

export default {
  props: {
    readOnly: { type: Boolean, default: false },
    setting: { type: SettingChoice },
  },
  methods: {
    select(option) {
      this.setting.SetValue(option);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
