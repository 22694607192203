<template>
  <div>
    <input :disabled="readOnly" v-model="value" type="text" :id="setting.name" :placeholder="setting.error" />
    <label :for="setting.name">{{ setting.displayName }}</label>
    <figure ref="preview" class="color-preview" />
    {{ setting.error }}
  </div>
</template>

<script>
import { SettingColor } from "../../code/classes/SettingColor";

export default {
  props: {
    readOnly: { type: Boolean, default: false },
    setting: { type: SettingColor },
  },
  watch: {
    "setting.value"() {
      this.updateColor();
    },
  },
  mounted() {
    this.updateColor();
  },
  computed: {
    value: {
      get() {
        return this.setting.value;
      },
      set(value) {
        this.setting.SetValue(value);
      },
    },
  },
  methods: {
    updateColor() {
      if (!this.setting.error) {
        this.$refs.preview.style.background = this.setting.value;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.color-preview {
  display: inline-block;
  width: 32px;
  height: 10px;
  margin: 0px 4px;
  border: 1px solid black;
}
</style>
