<template>
  <div class="testbed-root">
    <WalletTestbed />
  </div>
</template>
  
<script>
import WalletTestbed from "@/components/testbed/WalletTestbed.vue";

export default {
  components: {
    WalletTestbed,
  },
  watch: {
    "$firebase.account"(account) {
      console.log(`account changed ${account}`);
    },
  },
  mounted() {
    console.log(`mounted with firebase.account=${this.$firebase.account}`);
  },
};
</script>

<style lang="scss">
.testbed-root {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;

  font-size: 12px;
  line-height: 1.5;
  margin: 2px;
}
</style>
