<template>
  <div class="icontext-root">
    <div class="line">
      <img v-if="icon" class="icon" :src="icon || ''" :alt="name + ' icon'" />
      <div v-else class="icon capital">{{ name[0] }}</div>
      <div class="slot"><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icon: { type: String, default: "" },
    name: { type: String, default: "" },
  },
};
</script>

<style scoped lang="scss">
$size: 24px;
$border: 1px;
$color: #30f;

.icontext-root {
  display: flex;
  justify-content: center;

  .line {
    display: flex;
    flex-wrap: nowrap;
    gap: 4px;
    align-items: center;

    .icon {
      color: $color;
      min-width: $size;
      max-width: $size;
      min-height: $size;
      max-height: $size;
    }

    img {
      width: $size;
      height: $size;
    }

    .capital {
      box-sizing: border-box;
      border: $border dashed $color;
      border-radius: 50%;
      width: $size;
      height: $size;
      line-height: $size - 2 * $border;
      font-size: 0.75 * $size;
      font-weight: 700;
      text-align: center;
    }

    .slot {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
