<template>
  <button @click="click" :class="activity || disabled ? 'disabled' : undefined" class="button-root">
    <SimpleSpinner v-if="activity" class="activity" />
    <span class="text">
      <slot> </slot>
    </span>
  </button>
</template>

<script>
import SimpleSpinner from "./SimpleSpinner.vue";

export default {
  components: {
    SimpleSpinner,
  },
  props: {
    activity: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    click(event) {
      if (event) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }
      this.$emit("click");
    },
  },
};
</script>

<style scoped lang="scss">
.button-root {
  position: relative;

  .activity {
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
  }

  //.icon,
  .text {
    padding: 0px 12px;
    display: block;
    //border: 1px solid red;
  }
}

.button-root.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}
</style>
