<template>
  <div class="fetch-messages-root">
    <div>
      <ActivityButton :activity="messagesClass.isFetching" :disabled="messagesClass.isFetching" @click="Fetch">
        fetch
      </ActivityButton>
    </div>
  </div>
</template>

<script>
import { Messages } from "../../code/messages";
import ActivityButton from "./ActivityButton.vue";

export default {
  components: {
    ActivityButton,
  },
  props: {
    messagesClass: { type: Messages, default: undefined },
  },
  methods: {
    async Fetch() {
      const intervalStart = new Date();
      const intervalEnd = new Date();
      intervalStart.setDate(intervalStart.getDate() - 100);

      // this.messagesClass === this.$messages
      const messages = await this.messagesClass.FetchMessages(intervalStart, intervalEnd);
      if (messages.length <= 0) {
        console.log("no data");
      } else {
        messages.forEach((message) => {
          console.log(message);
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
</style>
