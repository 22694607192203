<template>
  <div class="setting-flags-root">
    {{ setting.name }}:
    <p>
      error?: <span v-if="!setting.valid">{{ setting.error }}</span>
    </p>
    <template v-for="flag in setting.value">
      <div v-bind:key="flag.name">
        <input :disabled="readOnly" type="checkbox" :id="flag.name" v-model="flag.value" />
        <label :for="flag.name">{{ flag.name }}</label>
      </div>
    </template>
  </div>
</template>

<script>
import { SettingFlags } from "../../code/classes/SettingFlags";

export default {
  props: {
    readOnly: { type: Boolean, default: false },
    setting: { type: SettingFlags },
  },
};
</script>

<style scoped lang="scss">
</style>
