<template>
  <div>
    <input :disabled="readOnly" v-model="value" type="number" :id="setting.name" />
    <label :for="setting.name">{{ setting.displayName }}</label>
  </div>
</template>

<script>
import { SettingNumber } from "../../code/classes/SettingNumber";

export default {
  props: {
    readOnly: { type: Boolean, default: false },
    setting: { type: SettingNumber },
  },
  computed: {
    value: {
      get() {
        return this.setting.value;
      },
      set(value) {
        this.setting.SetValue(value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
</style>
