import { render, staticRenderFns } from "./WalletWidget.vue?vue&type=template&id=1f79eccc&scoped=true"
import script from "./WalletWidget.vue?vue&type=script&lang=js"
export * from "./WalletWidget.vue?vue&type=script&lang=js"
import style0 from "./WalletWidget.vue?vue&type=style&index=0&id=1f79eccc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f79eccc",
  null
  
)

export default component.exports