<template>
  <div :class="classes" v-if="!setting?.hidden">
    <SettingBoolWidget v-if="setting?.type === 'SettingBool'" :setting="setting" :readOnly="readOnly" />
    <SettingChoiceWidget v-else-if="setting?.type === 'SettingChoice'" :setting="setting" :readOnly="readOnly" />
    <SettingColorWidget v-else-if="setting?.type === 'SettingColor'" :setting="setting" :readOnly="readOnly" />
    <SettingNumberWidget v-else-if="setting?.type === 'SettingNumber'" :setting="setting" :readOnly="readOnly" />
    <SettingSliderWidget v-else-if="setting?.type === 'SettingSlider'" :setting="setting" :readOnly="readOnly" />
    <SettingStringWidget v-else-if="setting?.type === 'SettingString'" :setting="setting" :readOnly="readOnly" />
    <SettingFlagsWidget v-else-if="setting?.type === 'SettingFlags'" :setting="setting" :readOnly="readOnly" />
  </div>
</template>

<script>
import { SettingBase } from "../../code/classes/SettingBase";
import SettingBoolWidget from "./SettingBoolWidget";
import SettingChoiceWidget from "./SettingChoiceWidget";
import SettingColorWidget from "./SettingColorWidget";
import SettingFlagsWidget from "./SettingFlagsWidget";
import SettingNumberWidget from "./SettingNumberWidget";
import SettingSliderWidget from "./SettingSliderWidget";
import SettingStringWidget from "./SettingStringWidget";

export default {
  components: {
    SettingBoolWidget,
    SettingChoiceWidget,
    SettingColorWidget,
    SettingFlagsWidget,
    SettingNumberWidget,
    SettingSliderWidget,
    SettingStringWidget,
  },
  props: {
    readOnly: { type: Boolean, default: false },
    setting: { type: SettingBase },
  },
  computed: {
    classes() {
      const classes = [];
      if (this.setting?.uninitialized) classes.push("uninitialized");
      if (this.setting?.disabled) classes.push("disabled");
      return classes.join(" ");
    },
  },
};
</script>

<style scoped lang="scss">
.uninitialized,
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
