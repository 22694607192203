<template>
  <div class="root" :class="classes">
    <span class="number">{{ $donatrix.message?.length }}</span>
    <span class="separator">/</span>
    <span class="limit">{{ $donatrix.messageLimit }}</span>
  </div>
</template>

<script>
export default {
  computed: {
    empty() {
      return "" === this.$donatrix.message;
    },
    overflow() {
      return this.$donatrix.message?.length > this.$donatrix.messageLimit;
    },
    classes() {
      let classes = [];
      if (this.overflow) classes.push("overflow");
      else if (this.empty) classes.push("empty");
      return classes.join(" ");
    },
  },
};
</script>

<style scoped lang="scss">
.root {
  display: inline;
  margin: 4px;
}

.separator,
.limit {
  font-size: xx-small;
}

.number {
  font-weight: 900;
}

.overflow {
  .number {
    color: red;
  }
}

.empty {
  .number {
    color: orange;
  }
}
</style>
