<template>
  <div v-if="enabled">
    <p>nickname: {{ $account.public.nickname }}</p>
    <div :class="pending ? 'disabled' : undefined">
      <div v-if="canChange">
        <input
          ref="input"
          placeholder="new nickname"
          v-model="nickname"
          v-on:keyup.enter="action"
          v-on:keyup.esc="onEsc"
          @input="onChange"
        />
        <ActivityButton :activity="pending" @click="action">{{ unique ? "change" : "verify" }}</ActivityButton>
      </div>
      <div v-if="canChangeIn">can change in {{ canChangeIn }}</div>
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import ActivityButton from "./ActivityButton.vue";

export default {
  components: {
    ActivityButton,
  },
  data() {
    return {
      unique: false,
      pending: false,
      nickname: undefined,
      canChange: false,
      canChangeIn: undefined,
    };
  },
  computed: {
    enabled() {
      return this.$firebase.account !== undefined;
    },
  },
  created() {
    this.H = setInterval(() => {
      if (!this.$account.public.nickname || !this.$account.public.nicknameUpdatedAt) {
        this.canChange = true;
        return;
      }
      const updatedAt = new Date(this.$account.public.nicknameUpdatedAt);
      const renameDelaySeconds = this.$donatrix.settings.renameDelaySeconds;
      const canUpdateAt = new Date(updatedAt.getTime() + renameDelaySeconds * 1000);
      const utcNow = new Date();
      this.canChange = utcNow >= canUpdateAt;
      if (this.canChange) {
        this.canChangeIn = undefined;
      } else {
        const seconds = this.canChange ? 0 : ((canUpdateAt - utcNow) / 1000).toFixed(0);
        this.canChangeIn = new Date(seconds * 1000).toISOString().slice(11, 19);
      }
    }, 100);
  },
  unmounted() {
    clearInterval(this.H);
  },
  methods: {
    onFiltered(value) {
      this.nickname = value;
    },
    onEsc() {
      this.$refs.input.blur();
    },
    onChange() {
      this.unique = false;
    },
    verify() {
      if (this.pending) return;
      this.pending = true;

      this.unique = false;
      this.$account
        .testNicknameIsUnique(this.nickname, this.onFiltered.bind(this))
        .then(() => {
          this.pending = false;
          this.unique = true;
          nextTick(() => {
            this.$refs.input.focus();
          });
        })
        .catch((err) => {
          console.error(err);
          this.pending = false;
          nextTick(() => {
            this.$refs.input.focus();
          });
        });
    },
    change() {
      if (this.pending) return;
      this.pending = true;

      this.$account
        .changeNickname(this.nickname)
        .then(() => {
          this.nickname = "";
          this.unique = false;
          this.pending = false;
        })
        .catch((err) => {
          console.error(err);
          this.unique = false;
          this.pending = false;
        });
    },
    action() {
      this.$refs.input.blur();
      if (!this.unique) this.verify();
      else this.change();
    },
  },
};
</script>

<style scoped lang="scss">
.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}
</style>
