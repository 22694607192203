<template>
  <div class="layout">
    <div class="sidebar">
      <WalletWidget :wallet="$metamaskWallet"/>
      <WalletWidget :wallet="$phantomWallet"/>
      <!-- <WalletWidget :wallet="$dummyWallet"/> -->
      <LimitsWidget/>
    </div>
    <div class="maingrid">
      <div class = "panel sflex">
        <!-- <ActivityButton :activity="$wallet.isPendingConnect" :disabled="!canConnect" @click="Connect">connect</ActivityButton> -->
        <!-- <ActivityButton :activity="$firebase.pendingSignIn" :disabled="!canSignIn" @click="SignIn">sign-in</ActivityButton> -->
        <ActivityButton :disabled="!canSignOut" @click="SignOut">sign-out</ActivityButton>
        <ActivityButton :activity="$firebase.pendingDeleteAccount" :disabled="$firebase.pendingDeleteAccount || !canDeleteAccount" @click="DeleteAccount">delete account</ActivityButton>
        <ActivityButton :activity="$account.generatingToken" :disabled="!canGenerateToken" @click="GenerateAccessToken">new token</ActivityButton>
        <ActivityButton :activity="$donatrix.isPendingDonate" :disabled="!canDonate" @click="Donate">donate</ActivityButton>
        <ActivityButton :activity="$donatrix.isPendingApprove" :disabled="!canApprove" @click="Approve">approve</ActivityButton>
        <div>
          <input v-model="testDonate" type="checkbox" id="testdonate"/><label for="testdonate">test donate</label>
        </div>
      </div>
      <div class = "panel sgrid smallpanel">
        <template v-for="chain in $donatrix.supportedNetworks">
          <ActivityButton :activity="$wallet.isPendingNetwork === chain.chainId" :disabled="$wallet.chainId === undefined || $wallet.chainId === chain.chainId" v-bind:key="chain.chainId" @click="ChangeNetwork(chain.chainId)">
            <NetworkIcon :icon="chain.icon" :name="chain.blockchain">
              {{chain.blockchain}}
            </NetworkIcon>
          </ActivityButton>
        </template>
      </div>
      <div class = "panel bigpanel">
        <p>wallet type: {{$wallet.type}}</p>
        <!-- <p>wallet mode: {{$wallet.walletMode}}</p> -->
        <p>wallet chainId: {{$wallet.chainId}}</p>
        <!-- <p>wallet isConnected: {{$wallet.isConnected}}</p> -->
        <p>wallet address: {{$wallet.address}}</p>
        <p>---</p>
        <p>firebase initialized: {{$firebase.initialized}}</p>
        <p>firebase authenticated: {{$firebase.authenticated}}</p>
        <p>firebase account: {{$firebase.account}}</p>
        <p>firebase read-only: {{$firebase.readOnly}}</p>
        <p>firebase error type: <span class="error">{{$firebase.errorType}}</span></p>
        <p>firebase error value: <span class="error">{{$firebase.errorValue}}</span></p>
        <p>---</p>
        <p>EVM addr: {{$account.public?.evmAddress}}</p>
        <p>Solana addr: {{$account.public?.solanaAddress}}</p>
        <p>access token:
          <a v-if="$account.account.accessToken" :href="'/?token='+$account.account.accessToken" target="_blank">{{$account.account.accessToken}}</a>
          <span v-if="$account.account.accessToken"></span></p>
        <p>num pending transactions: {{$account.account.pendingTransactions?.length}}</p>
        <NicknameWidget/>
      </div>

      <div class="panel" :class="$donatrix.isSupportedChain ? 'ok' : 'locked'">
        <p>donatrix supported chain: {{$donatrix.isSupportedChain}}</p>
        <p>donatrix network: {{$donatrix.network}}</p>
        <p>donatrix address: {{$donatrix.donatrixAddress || $donatrix.feeAddress}}</p>
        <p>donatrix service fee: {{$donatrix.serviceFeeUI}}</p>
        <p v-if="$donatrix.isSupportedChain">Supported currency in {{$donatrix.network}}:
          <ul>
            <li v-for="item in $donatrix.supportedTokens" :key="item.address">
              <span v-if="item.isNative"><a href="javascript:void(0)" @click="$donatrix.SelectToken(item.address)">{{item.name}}</a></span>
              <span v-else><a href="javascript:void(0)" @click="$donatrix.SelectToken(item.address)">{{item.name}}</a> {{item.address}}</span>
            </li>
          </ul>
        </p>
        <!-- <p>Supported networks:
          <ul>
            <li v-for="network in $donatrix.supportedNetworks" :key="network.chainId">{{network.blockchain}}</li>
          </ul>
        </p> -->
        <p>selected currency: {{$donatrix.selectedToken?.name}}</p>

        <br>
        <p>all tickers: {{$donatrix.settings.tickerOrder}}</p>
        <br>

        <div :class="$donatrix.isPendingDonate ? 'locked': undefined">
          <div>to account: <input disabled v-model="$donatrix.donateTo" placeholder="Donate to address"/> {{validRecepient === true ? "valid": validRecepient === false ? "invalid" : undefined }}</div>
          <div>to address: {{$donatrix.donateToAddress}}</div>
          <div>donate to: {{$donatrix.recipientData.nickname}}</div><br>
          <div>
            from:
            <input v-if="$donatrix.customNickname" v-model="$donatrix.fromNickname" placeholder="anonymous"/>
            <input v-else disabled :value="$account.public.nickname" placeholder="anonymous">
            <input v-model="$donatrix.customNickname" type="checkbox" id="customNickname"/><label for="customNickname">custom nickname</label>
          </div>
          <div>message: <input v-model="$donatrix.message" placeholder="message"/><MessageLimit/></div>
          <div>value: <input v-model="$donatrix.selectedAmount" placeholder="donate amount"/></div>
          <p v-if="$donatrix.isUpdatingBalance"><SimpleSpinner/></p>
          <p v-else >balance: {{$donatrix.balance}} allowance: {{$donatrix.allowance}}</p>
          <p v-if="$donatrix.donatrixError">error: <span class="error">{{$donatrix.donatrixError}}</span></p>
        </div>
      </div>

      <div class="panel">
        <div>
          <input v-model="showMessages" type="checkbox" id="showMessages"/><label for="showMessages">show messages</label>
        </div>
        <MessagesWidget v-if="showMessages" type="incoming"/>
        <div>
          <input v-model="showVariations" type="checkbox" id="showVariations"/><label for="showVariations">show variations</label>
        </div>
        <ManageVariationsWidget v-if="showVariations" :manager="$account?.variationManager"/>
      </div>
      <!-- <div class="panel">
        <MessagesWidget type="outgoing"/>
      </div> -->

      <div class="panel">
        <p>donate to: {{$donatrix.recipientData.nickname}}</p>
        <p>limits:</p>
        <ul>
          <li v-for="val, cur in $donatrix.donateLimits" v-bind:key="cur">
            {{cur}}: {{val}}
          </li>
        </ul>
        <AccountSettingsWidget :accountSettings="$account.accountSettings"/>
      </div>
      <div class="panel">
        <FetchMessagesWidget :messagesClass="$messages"/>
      </div>
    </div>
  </div>
</template>

<script>
import AccountSettingsWidget from "./AccountSettingsWidget.vue";
import ActivityButton from "./ActivityButton.vue";
import FetchMessagesWidget from "./FetchMessagesWidget.vue";
import LimitsWidget from "./LimitsWidget.vue";
import ManageVariationsWidget from "./ManageVariationsWidget";
import MessageLimit from "./MessageLimit.vue";
import MessagesWidget from "./MessagesWidget.vue";
import NetworkIcon from "./NetworkIcon.vue";
import NicknameWidget from "./NicknameWidget.vue";
import SimpleSpinner from "./SimpleSpinner.vue";
import WalletWidget from "./WalletWidget.vue";
import { DONATRIX_ERROR } from "../../code/donatrix";

export default {
  components: {
    AccountSettingsWidget,
    ActivityButton,
    FetchMessagesWidget,
    ManageVariationsWidget,
    MessageLimit,
    MessagesWidget,
    LimitsWidget,
    NetworkIcon,
    NicknameWidget,
    SimpleSpinner,
    WalletWidget,
  },
  data() {
    return {
      showMessages: false,
      showVariations: false,
      testDonate: false,
    };
  },
  computed: {
    validRecepient() {
      return this.$donatrix.recipientData.valid; // true|false|undefined
    },
    canConnect() {
      if (this.$wallet.isPendingConnect) {
        return false;
      }
      return this.$wallet.detected && this.$wallet.address === undefined;
    },
    canSignIn() {
      return (
        this.$wallet.address !== undefined &&
        !this.$firebase.pendingSignIn &&
        this.$firebase.initialized &&
        !this.$firebase.account
      );
    },
    canSignOut() {
      return this.$firebase.authenticated;
    },
    canDeleteAccount() {
      return this.$firebase.account !== undefined;
    },
    canGenerateToken() {
      return !this.$account.disableTokenGenerator && !this.$account.generatingToken;
    },
    canDonate() {
      if (this.testDonate) return !this.$donatrix.isPendingDonate;
      return this.$donatrix.canSpend && this.$donatrix.donatrixError === undefined && !this.$wallet.isPendingTx;
    },
    canApprove() {
      return (
        this.$donatrix.canApprove &&
        this.$donatrix.donatrixError === DONATRIX_ERROR.LowAllowance &&
        !this.$wallet.isPendingTx
      );
    },
  },
  methods: {
    Connect() {
      this.$wallet.Action();
    },
    SignIn() {
      this.$firebase.SignIn();
    },
    SignOut() {
      this.$firebase.SignOut();
    },
    DeleteAccount() {
      this.$firebase.DeleteAccount();
    },
    GenerateAccessToken() {
      this.$account.GenerateAccessToken();
    },
    Donate() {
      this.$donatrix.Donate(this.testDonate);
    },
    Approve() {
      this.$donatrix.Approve();
    },
    ChangeNetwork(chainId) {
      this.$wallet.ChangeNetwork(chainId);
    },
  },
};
</script>

<style scoped lang="scss">
.layout {
  display: flex;
  gap: 4px;
}

.maingrid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 4px;
  justify-content: left;
}
.sflex {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-content: flex-start;
}
.sgrid {
  border: 1px solid red;
  gap: 4px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-content: flex-start;
  //align-self: flex-start;
  //align-items: flex-start;
}

.locked {
  pointer-events: none;
  user-select: none;
  opacity: 0.5;
}

.top {
  margin-bottom: 4px;
}

.panel {
  width: 500px;
  border: 1px solid red;
  //margin: 4px 0px;
  padding: 12px;
}

.smallpanel {
  min-height: 90px;
}
.bigpanel {
  min-height: 500px;
}

.sidebar {
  border: 1px solid red;
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.error {
  color: red;
}
</style>
