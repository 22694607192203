<template>
  <div class="manage-variation-root">
    <!-- created: {{ variation.createdAt.toISOString() }} -->
    name: {{ variation.displayName }}
    <div class="list">
      <template v-for="setting in variation.allSettings">
        <SettingProxyWidget v-bind:key="setting.displayName" :setting="setting" />
      </template>
    </div>
    <div class="controls">
      <span class="controls-group">
        <button :disabled="variation.isSaving || !variation.dirty || !variation.valid" @click="Save">save</button>
        <button :disabled="!(!variation.isTesting && variation.isCommitted && variation.enabled)" @click="Test">
          test
        </button>
      </span>
      <span class="controls-group">
        <button :disabled="variation.isSaving" @click="Delete" v-if="variation.type !== 'default'">delete</button>
      </span>
    </div>
  </div>
</template>

<script>
import { NotificationVariationBase } from "../../code/classes/NotificationVariationBase";
import SettingProxyWidget from "./SettingProxyWidget.vue";

export default {
  components: {
    SettingProxyWidget,
  },
  props: {
    variation: { type: NotificationVariationBase, default: undefined },
  },
  methods: {
    Save() {
      this.variation?.Save();
    },
    Test() {
      this.variation?.Test();
    },
    Delete() {
      this.variation?.Delete();
    },
  },
};
</script>

<style scoped lang="scss">
.manage-variation-root {
  margin: 5px 0px;
  border: 1px solid black;

  .list {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 12px 0px;
  }

  .controls {
    display: flex;
    justify-content: space-between;

    .controls-group {
      display: flex;
      gap: 2px;
    }
  }
}
</style>
