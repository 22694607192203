<template>
  <div class="account-settings-root" :class="{ readonly: $firebase.readOnly }">
    <p>---</p>
    <p>Account settings:</p>
    <template v-for="setting in accountSettings.allSettings">
      <SettingProxyWidget v-bind:key="setting.displayName" :setting="setting" />
    </template>
    <p>---</p>
    <ActivityButton :activity="isSaving" :disabled="!accountSettings.dirty" @click="Save">Save</ActivityButton>
    <ActivityButton :disabled="!accountSettings.dirty" @click="Revert">Revert</ActivityButton>
  </div>
</template>

<script>
import { AccountSettings } from "../../code/classes/AccountSettings";
import ActivityButton from "./ActivityButton.vue";
import SettingProxyWidget from "./SettingProxyWidget.vue";

export default {
  components: {
    ActivityButton,
    SettingProxyWidget,
  },
  props: {
    accountSettings: { type: AccountSettings },
  },
  data() {
    return {
      isSaving: false,
    };
  },
  methods: {
    async Save() {
      if (this.isSaving) return;
      this.isSaving = true;

      this.accountSettings
        .Save()
        .then(() => {
          this.isSaving = false;
        })
        .catch((error) => {
          console.error(error);
          this.isSaving = false;
        });
    },
    Revert() {
      this.accountSettings.Revert();
    },
  },
};
</script>

<style scoped lang="scss">
.readonly {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}
</style>
