import { render, staticRenderFns } from "./SettingStringWidget.vue?vue&type=template&id=d5125afe&scoped=true"
import script from "./SettingStringWidget.vue?vue&type=script&lang=js"
export * from "./SettingStringWidget.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5125afe",
  null
  
)

export default component.exports