<template>
  <div class="manage-variations-root" :class="{ readonly: !$firebase.account || $firebase.readOnly }">
    <div class="preview-links">
      <template v-for="(n, index) in 6">
        <a :href="`/view?token=${$account?.account.accessToken}&groupIndex=${n}`" v-bind:key="index" target="_blank">
          preview group {{ n }}
        </a>
      </template>
      <Notification/>
    </div>
    <button :disabled="!(manager.isLoaded && manager.canCreate)" @click="AddVariation">Add variation</button>
    <p v-if="manager.isLoading">loading...</p>
    <template v-for="variation of manager.variations">
      <div v-bind:key="variation.uuid">
        <ManageVariationWidget :variation="variation" />
      </div>
    </template>
  </div>
</template>

<script>
import { NotificationVariationManager } from "../../code/classes/NotificationVariationManager";
import ManageVariationWidget from "./ManageVariationWidget.vue";
import Notification from "./Notification.vue";

export default {
  components: {
    ManageVariationWidget,
    Notification,
  },
  props: {
    manager: { type: NotificationVariationManager, default: undefined },
  },
  watch: {
    "$firebase.account"() {
      this.Reload();
    },
    "$donatrix.settingsReady"() {
      this.Reload();
    },
  },
  mounted() {
    this.Reload();
  },
  methods: {
    Reload() {
      if (!this.$firebase.account) return;
      if (!this.$donatrix.settingsReady) return;
      this.manager.LoadVariations();
    },
    AddVariation() {
      this.manager.CreateVariation("fiat");
    },
  },
};
</script>

<style scoped lang="scss">
.manage-variations-root.readonly {
  user-select: none;
  pointer-events: none;
  opacity: 0.5;
}

.manage-variations-root {
  .preview-links {
    margin: 12px 0px;
    display: grid;
    justify-content: space-around;
    grid-template-columns: repeat(3, auto);
    column-gap: 8px;
    row-gap: 8px;
    //border: 1px solid red;

    a {
      white-space: nowrap;
      //border: 1px solid red;
      text-align: center;
    }
  }
}
</style>
