<template>
    <div>
        <p>NotificationView</p>
        <p>account: {{ $firebase.account }}</p>
        <p>read-only: {{ $firebase.readOnly }}</p>
        <NotificationWidget :manager="$account.variationManager" />
    </div>
</template>
  
<script>
import NotificationWidget from "./NotificationWidget.vue";

export default {
    components: {
        NotificationWidget,
    },
 
    watch: {
        "$firebase.account"() {
            this.Reload();
        },
        "$donatrix.settingsReady"() {
            this.Reload();
        },
    },
    mounted() {
        this.Reload();
    },
    methods: {
        Reload() {
            if (!this.$firebase.account) return;
            if (!this.$donatrix.settingsReady) return;
            this.$account.variationManager.LoadVariations();
            this.$messages.StartListen();
        },
    },
};
</script>
  
<style scoped lang="scss"></style>
  