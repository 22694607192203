<template>
  <div class="limits-root" :class="classes" v-if="$account.limits.limits.length">
    <label>my limits:</label>
    <template v-for="limit of $account.limits.limits">
      <LimitControl v-bind:key="limit.ticker" :limit="limit"/>
    </template>
    <ActivityButton :activity="$account.limits.pendingOperation" :disabled="!($account.limits.canSave && !$account.limits.pendingOperation)" @click="save">save</ActivityButton>
  </div>
</template>

<script>

import ActivityButton from "./ActivityButton.vue";
import LimitControl from "./LimitControl.vue";

export default {
  components: {
    ActivityButton,
    LimitControl,
  },
  computed: {
    classes() {
      const disabled = this.$account.limits.pendingOperation || this.$account.limits.loading;
      return disabled ? "disabled" : undefined;
    },
  },
  methods: {
    save() {
      this.$account.limits.Commit();
    }
  },
}
</script>

<style scoped lang="scss">
.limits-root {
  display: flex;
  flex-direction: column;
  gap: 4px;

  border: 1px solid black;
  padding: 4px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

</style>
