<template>
  <div class="testbed-notification">
    notification placeholder
    <div class="message" v-if="message">
      [{{ message?.date }}] [{{ message?.uiValue }}] [{{ message?.nickname }}]: {{ message?.text }}
      <div>
        <p>blockchain: {{ message?.blockchain }}</p>
        <p>native value: {{ message?.nativeValue }} {{ message?.ticker }}</p>
      </div>
      [variation:{{ variation?.displayName }}]
    </div>
  </div>
</template>

<script>
import { Message } from "../../code/classes/Message";
import { NotificationVariationManager } from "../../code/classes/NotificationVariationManager";
import { NotificationVariationRuntime } from "../../code/classes/NotificationVariationRuntime";

export default {
  props: {
    manager: { type: NotificationVariationManager, default: undefined },
  },
  data() {
    return {
      message: undefined,
      variation: 1,
    };
  },
  mounted() {
    this.manager?.RegisterDisplay(this.DisplayMessage.bind(this));
  },
  destroyed() {
    this.manager?.UnregisterDisplay();
    clearTimeout(this.h);
  },
  methods: {
    DisplayMessage(message, variation) {
      if (!(message instanceof Message)) throw "unexpected message type";
      if (!(variation instanceof NotificationVariationRuntime)) throw "unexpected variation type";
      this.message = message;
      this.variation = variation;

      clearTimeout(this.h);
      this.h = setTimeout(() => {
        this.message = undefined;
        this.variation = undefined;
      }, variation.alertDurationSeconds * 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.testbed-notification {
  .removing {
    user-select: none;
    pointer-events: none;
    opacity: 0.8;
  }

  .message {
    padding: 2px 2px;
    border: 1px solid black;
    overflow-wrap: anywhere;
  }

  .fake {
    background-image: repeating-linear-gradient(-45deg, #f702, #f702 8px, #0000 8px, #0000 22px);
  }
}
</style>
